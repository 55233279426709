<template>
  <div class="animated fadeIn">
    <loading :active="isLoading || isLoadingCart"></loading>
    <div class="container mb-5">
      <div class="card bg-white p-5 shadow mt-6">
        <div class="card-body">
          <div class="py-3 text-center">
            <h1 class="h3">{{ $t('message.reset_password') }}</h1>
          </div>
          <form @submit.prevent="submit">
            <b-row>
              <b-col sm="12">
                <validation-field :label="$t('message.email')"
                                  :v="$v.form.email">
                  <input class="form-control" id="email"
                         maxlength="50"
                         type="text"
                         v-model="form.email"
                         v-on:blur="$v.form.email.$touch()"
                  />
                </validation-field>
              </b-col>
            </b-row>
            <hr class="mb-4">
            <b-row>
              <b-col sm="12" md="6" xl="6">
                <b-button :class="{invalid:$v.form.$invalid}" block
                          type="submit" class="bg-theme-secondary">
                  {{$t('message.button_send_text')}}
                </b-button>
              </b-col>
              <b-col sm="12" md="6" xl="6">
                <b-button block type="button" v-on:click="reset()"
                          variant="secondary">
                  {{$t('message.button_reset_text')}}
                </b-button>
              </b-col>
          </b-row>
          </form>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
    import ValidationField from "../components/ui/ValidationField";
    import ApiResolver from '../components/common/ApiResolver';
    import Api from '../components/common/Api';
    import {email, required} from 'vuelidate/lib/validators'
    import Loading from 'vue-loading-overlay';
    // Import stylesheet
    import 'vue-loading-overlay/dist/vue-loading.css';

    export default {
        name: "ResetPassword",
        components: {
            ValidationField,
          Loading
        },
        data() {
            return {
                form: {
                    email: null,
                },
                error_message: null,
                isLoading: false,
            }
        },
        methods: {
            submit() {
                this.$v.form.$touch();
                if (this.$v.form.$invalid) return;
                this.isLoading = true;
                Api
                    .post(ApiResolver.get('RESET_PASSWORD'), JSON.stringify(this.form))
                    .then(response => {

                        this.$store.dispatch('alerts/setAlert', {
                            type: 'success',
                            text: this.$t('message.reset_password_successfull')
                        });

                        this.isLoading = false;

                        /** redirect alla login **/

                        //window.location.href = '/login';
                        this.$router.push({name:'login'})

                    })
                    .catch(reason => {

                        this.isLoading = false;

                        this.error_message = reason.title + ' : ' + reason.detail;

                        this.$store.dispatch('alerts/setAlert', {
                            type: 'danger',
                            text: this.error_message
                        });
                    });
            },
            reset() {

                this.form = {
                    email: null,
                };
                this.$v.form.reset();
            }
        },
        validations: {
            form: {
                email: {
                    required: required,
                    email
                }
            }
        }
    }
</script>

<style scoped>
  .hasError label {
    color: red;
  }

  .error {
    color: red
  }
</style>
